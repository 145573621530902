import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../service/storage.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import async from 'async-es';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-drap-and-drop',
  templateUrl: './drap-and-drop.component.html',
  styleUrls: ['./drap-and-drop.component.css']
})
export class DrapAndDropComponent implements OnInit {
  files: any[] = [];
  progress = 0;
  enableSubmit = false;
  @Input() storageIdentifier: string;
  @Input() operation: string;
  @Output() imageUrl = new EventEmitter();

  constructor(private storageService: StorageService, private _snackBar: MatSnackBar) { }

  ngOnInit() { }

  onFileDropped($event) {
    if ((this.files.length + $event.length) <= 8)
      this.prepareFilesList($event);
    else
      this.openSnackBar("Maximum 8 files allowed ");
  }

  fileBrowseHandler(files) {
    if ((this.files.length + files.length) <= 8)
      this.prepareFilesList(files);
    else
      this.openSnackBar("Maximum 8 files allowed ");
  }

  deleteFile(index: number, url) {
    if (this.operation != 'update')
      this.storageService.deleteFileFromStorageByUrl(url);
    this.files.splice(index, 1);
    if (this.files.length < 2) {
      this.enableSubmit = false;
    }
  }

  async prepareFilesList(files: Array<any>) {
    this.enableSubmit = false;
    document.getElementById('fileDropRef').setAttribute('disabled', 'true');
    for (const item of files) {
      if (item.name.toLowerCase().endsWith('jpg') || item.name.toLowerCase().endsWith('png')) {
        let duplicateFile = -1;
        duplicateFile = this.files.findIndex((file) => item.name == file.name);
        if (duplicateFile > -1) {
          this.openSnackBar("Same file can't be uploded twice");
        } else {
          item.progress = 0;
          this.files.push(item);
        }
      } else {
        this.openSnackBar("Only PNG & JPG files are allowed ");
      }
    }

    async.forEachOf(this.files, (value, key, callback) => {
      if (value.progress < 100) {
        var imageFile = value;
        let observer$ = this.storageService.pushDragFileToStorage(this.storageIdentifier, imageFile);
        observer$.uploadProgress$.subscribe(
          percentage => { imageFile.progress = Math.round(percentage); },
          error => { console.log(error); }
        );
        observer$.downloadUrl$.subscribe(
          (url) => {
            imageFile.path = url;
            callback();
          },
          error => { console.log(error); }
        );
      } else {
        callback();
      }
    }, (err) => {
      if (err) {
        console.error(err.message);
      }
      document.getElementById('fileDropRef').removeAttribute('disabled');
      if (this.files.length >= 2) {
        this.enableSubmit = true;
      }
    });

    // this.storageService.pushDragFileToStorage(this.storageIdentifier, this.files[this.files.length - 1]).uploadProgress$.subscribe(
    //   percentage => { this.files[this.files.length - 1].progress = Math.round(percentage); },
    //   error => { console.log(error); }
    // );
    // this.storageService.pushDragFileToStorage(this.storageIdentifier, this.files[this.files.length - 1]).downloadUrl$.subscribe(
    //   (url) => {
    //     this.files[this.files.length - 1].path = url;
    //     document.getElementById('fileDropRef').removeAttribute('disabled');
    //     let temp = [];
    //     if (this.files.length >= 2) {
    //       temp = this.files.filter(files => files.path);
    //       if (temp.length == this.files.length) {
    //         this.enableSubmit = true;
    //       }
    //     }
    //   },
    //   error => { console.log(error); }
    // );
  }


  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
  }

  sendImageData(url) {
    this.imageUrl.emit(url);
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '✕', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['warning']
    });
  }

  uploadFilesSimulator(index: number) {
    if (index === this.files.length) {
      return;
    } else {
      const progressInterval = setInterval(() => {
        if (this.files[index].progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator(index + 1);
        } else {
          this.files[index].progress += 5;
        }
      }, 200);
    }
  }
}
