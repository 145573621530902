import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { message } from '../constants/message';

@Component({
  selector: 'app-log-book',
  templateUrl: './log-book.component.html',
  styleUrls: ['./log-book.component.css']
})
export class LogBookComponent implements OnInit, OnChanges {
  @Input() appData;
  @Output() close = new EventEmitter();
  appName: string;
  appId: string;
  history: any;
  action_enum = message.LOG_BOOK_ACTION;
  loading: boolean = false;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    this.appName = changes.appData.currentValue.name;
    this.appId = changes.appData.currentValue.id;
    this.getAppHistory();
  }

  getAppHistory() {
    this.authenticationService.getAppHistory(this.appId).then((resp: any) => {
      if (resp.errorCode == 0) {
        this.history = resp.data;
        this.loading = false;
      } else {
        this.errorHandling(resp.errorCode, this.getAppHistory);
      }
    }).catch((err) => {
      console.log(err);
      this.loading = false;
    })
  }

  closeLogBook() {
    this.close.emit();
  }

  formatDate(date) {
    let tempDate = new Date(date * 1000)
    let month = tempDate.toLocaleString('default', { month: 'short' });
    let day = tempDate.getDate();
    let year = tempDate.getFullYear();
    return `${month} ${day}, ${year}, ${tempDate.toLocaleTimeString('en-US', { hour12: false })}`;
  }

  async errorHandling(errorCode, cb) {
    this.loading = false;
    if (errorCode == 4013) {
      await this.authenticationService.refreshTokenGenerater();
      cb.bind(this)();
    }
  }

}
