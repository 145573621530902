import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'update-field',
  templateUrl: './update-field.component.html',
  styleUrls: ['./update-field.component.css']
})
export class UpdateFieldComponent implements OnInit {
  @Input() label: string;
  @Input() fileName: string;
  @Output() remove = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  clearInput() {
    console.log('called');
    this.remove.emit();
  }
}
