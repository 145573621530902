<div>
    <div class="container">
        <div class="progress-bar" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <div class="wrappper">
            <div class="left-side">
                <img height="100%" width="80%" src="../../assets/images/partial-desktop.png" alt="background_img" />
            </div>
            <div class="right-side">
                <div class="login-container">
                    <div class="app-title">
                        <div class="app-logo">
                            <img height="86px" width="83px" src="../../assets/images/App logo@2x.png"
                                alt="background_img" />
                        </div>
                        <div class="app-name">
                            MISSION CONTROL<span class="green-circle"></span>
                        </div>
                    </div>
                    <div class="login-btn-wrapper">
                        <div class="" (click)="loginWithGoogle()">
                            <!-- <span>G &nbsp;</span><span>Login with Google </span> -->
                            <img src="../../assets/images//CTA@2x.png" width="228px" height="86px">
                        </div>
                    </div>
                    <div class="login-btn-wrapper" *ngIf="!isProd">
                        <input type='text' #token id="username">
                        <button (click)="fakeLogin(token.value)">Login</button>
                    </div>
                    <div class="error-container" *ngIf="isError">
                        <p> {{errorMsg}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>