<div class="logbook-wrapper">
    <div class="progress-bar" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="title">{{appName}} Captain's logbook</div>
    <div class="history-table">
        <table>
            <tr *ngFor="let data of history;let i = index;">
                <td><span>{{formatDate(data.date._seconds)}}</span></td>
                <td><span>{{data.createdBy.globerName.join(' ')}}</span></td>
                <td><span>{{action_enum[data.action]}}</span></td>
                <td>{{(data.comment) ? data.comment : ''}}</td>
            </tr>
        </table>
    </div>
    <div class="close-btn" (click)="closeLogBook()"><span>CLOSE</span></div>
</div>