import { Component, OnInit, Renderer2, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { StorageService } from '../service/storage.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { message } from '../constants/message';
import { UserValidators, FileTypeValidatior } from '../customError';
import { DrapAndDropComponent } from '../drap-and-drop/drap-and-drop.component'

@Component({
  selector: 'add-app',
  templateUrl: './add-app.component.html',
  styleUrls: ['./add-app.component.css']
})
export class AddAppComponent implements OnInit {

  @ViewChild(DrapAndDropComponent) dragComponent: DrapAndDropComponent;
  @Output() close = new EventEmitter();
  @Output() imageUrlAd = new EventEmitter();
  openAddNewAppPanel: boolean = false;
  public files1; public files2; public files3; public files4;
  newAppAddPreForm: FormGroup;
  newAppAddForm: FormGroup;
  showNewAppForm: boolean = true;
  isChecked: boolean = true;
  percentage: any = 0;
  downloadUrl: any;
  percentageIpa: any = 0;
  downloadUrlIpa: any;
  percentageVideo: any = 0;
  downloadUrlVideo: any;
  percentageBnnr: any = 0;
  downloadUrlBnnr: any;
  currentAppLogo: string = message.DEFAULT_APP_LOGO;
  currentOpenedPanelTitle: string;
  currentSelectedApp: any;
  currrentAppIndex: any;
  currentOperation: string;
  typeOfUpdate = message.TYPE_OF_UPDATE;
  storageIdentifier: string;
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  hideAppLogoField: boolean = false;
  ipaUploadTask: any;
  videoUplodTask: any;

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private renderer2: Renderer2, private storageService: StorageService, private service: UserValidators) {
    this.initializeNewAppAddPreForm();
    this.initializeNewAppAddForm();
  }

  // new app add pre form
  initializeNewAppAddPreForm() {
    this.newAppAddPreForm = new FormGroup({
      newAppLinkName: new FormControl('', [
        Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)
      ], [this.service.userValidator()]),
      newAppVersion: new FormControl('', [
        Validators.required, Validators.pattern(/^[1-9][0-9]*(\.\d+){0,2}$/)
      ]),
      newAppBunbleIdf: new FormControl('', [
        Validators.required, Validators.pattern(/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+){0,10}$/)
      ], this.service.bundleIdfValidator())
    });
  }

  //new app add post form
  initializeNewAppAddForm() {
    this.newAppAddForm = new FormGroup({
      newAppExecutableFile: new FormControl(this.files2, [
        Validators.required, FileValidator.maxContentSize(1048576000), FileTypeValidatior('.ipa')
      ]),
      newAppLogo: new FormControl(this.files1, [
        Validators.required, FileValidator.maxContentSize(5242880), FileTypeValidatior('.png')
      ]),
      newAppDemoVideo: new FormControl(this.files3, [
        FileValidator.maxContentSize(524288000), FileTypeValidatior(['.mov', '.mp4', '.m4v'])
      ]),
      newAppDescription: new FormControl('', [
        Validators.required
      ]),
      newAppInstruction: new FormControl('', [
      ]),
      newAppBanner: new FormControl(this.files4, [
        Validators.required, FileValidator.maxContentSize(5242880), FileTypeValidatior(['.png', '.jpg'])
      ])
    });
  }

  ngOnInit(): void {

    // Uploading New App IPA file
    this.newAppAddForm.controls['newAppExecutableFile'].valueChanges.subscribe((files: any) => {
      this.ipaUploadTask && this.ipaUploadTask.cancel();
      if (this.newAppAddForm.controls['newAppExecutableFile'].valid) {
        if (!Array.isArray(files)) {
          this.files2 = [files];
        } else {
          this.files2 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'ipa', this.files2);
        this.ipaUploadTask = observable.uploadTask;
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentageIpa = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrlIpa = downloadUrl.split("&token")[0]; },
          (error) => { console.log(error); }
        );
      }
    });

    // uploading new app Logo
    this.newAppAddForm.controls['newAppLogo'].valueChanges.subscribe((files: any) => {
      if (this.newAppAddForm.controls['newAppLogo'].valid) {
        if (!Array.isArray(files)) {
          this.files1 = [files];
        } else {
          this.files1 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'logo', this.files1);
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentage = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrl = downloadUrl; },
          (error) => { console.log(error); }
        );
      }
    });

    // uploading new app Video file
    this.newAppAddForm.controls['newAppDemoVideo'].valueChanges.subscribe((files: any) => {
      this.videoUplodTask && this.videoUplodTask.cancel();
      if (this.newAppAddForm.controls['newAppDemoVideo'].dirty && this.newAppAddForm.controls['newAppDemoVideo'].valid) {
        if (!Array.isArray(files)) {
          this.files3 = [files];
        } else {
          this.files3 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'video', this.files3);
        this.videoUplodTask = observable.uploadTask;
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentageVideo = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrlVideo = downloadUrl; },
          (error) => { console.log(error); }
        );
      }
    });

    // uploading New App Banner image 
    this.newAppAddForm.controls['newAppBanner'].valueChanges.subscribe((files: any) => {
      if (this.newAppAddForm.controls['newAppBanner'].valid) {
        if (!Array.isArray(files)) {
          this.files4 = [files];
        } else {
          this.files4 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'banner', this.files4);
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentageBnnr = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrlBnnr = downloadUrl; },
          (error) => { console.log(error); }
        );
      }
    });
  }

  verifyNewAppCred(form: FormGroup) {
    this.isSubmitting = true;
    this.authenticationService.getUniqueIdentifierForNewApp(form.value).then(async (data: any) => {
      if (data.errorCode == 0) {
        form.disable();
        this.showNewAppForm = false;
        this.storageIdentifier = data.data.id;
        this.isSubmitting = false;
      } else if (data.errorCode == 4013) {
        await this.authenticationService.refreshTokenGenerater();
        this.verifyNewAppCred(form)
      }
    }).catch((err) => {
      this.isSubmitting = false;
    })
  }

  openAddNewAppLinkToStorePanel(opertation) {
    this.currentOperation = message.OPERTAION_TYPE['4'];
    this.openAddNewAppPanel = true;
    this.currentAppLogo = message.DEFAULT_APP_LOGO;

  }

  submitApp(formData) {
    this.isSubmitting = true;
    let temp = []
    this.dragComponent.files.forEach((file) => {
      let obj = new Object();
      obj['name'] = file.name;
      obj['path'] = file.path;
      obj['progress'] = 100;
      obj['size'] = file.size;
      temp.push(obj);
    })
    let obj = {
      'id': this.storageIdentifier,
      'name': this.newAppAddPreForm.controls['newAppLinkName'].value,
      'version': this.newAppAddPreForm.controls['newAppVersion'].value,
      'bIdentifier': this.newAppAddPreForm.controls['newAppBunbleIdf'].value,
      'details': { 'description': formData.newAppDescription, 'instruction': formData.newAppInstruction },
      'logo': this.downloadUrl,
      'logoName': this.files1[0]._files[0].name,
      "ipa": { "path": this.downloadUrlIpa, "name": this.files2[0]._files[0].name },
      "video": (this.downloadUrlVideo) ? { "path": this.downloadUrlVideo, "name": this.files3[0]._files[0].name } : null,
      "banner": { "path": this.downloadUrlBnnr, "name": this.files4[0]._files[0].name },
      "screens": temp,
      "isDisabled": !this.isChecked,
      "size": this.files2[0]._files[0].size
    }
    this.authenticationService.submitApp(obj).then(async (data: any) => {
      if (data.errorCode == 0) {
        this.downloadUrl = undefined; this.downloadUrlIpa = undefined;
        this.downloadUrlVideo = undefined; this.downloadUrlBnnr = undefined;
        this.closePanel();
        this.isSubmitting = false;
      } else if (data.errorCode == 4013) {
        await this.authenticationService.refreshTokenGenerater();
        this.submitApp(formData)
      }
    }).catch((err) => {
      this.isSubmitting = false;
    })
  }

  resetAddAppForm() {
    this.showNewAppForm = true;

    this.files2 && this.percentageIpa < 100 && this.ipaUploadTask.cancel();
    this.files3 && this.percentageVideo < 100 && this.videoUplodTask.cancel();

    // this.newAppAddForm.get('newAppDemoVideo').clearValidators();
    // this.newAppAddForm.get('newAppDemoVideo').updateValueAndValidity();
    this.files1 = undefined; this.files2 = undefined; this.files3 = undefined; this.files4 = undefined;
    this.initializeNewAppAddPreForm();
    this.newAppAddPreForm.enable();
    this.initializeNewAppAddForm();
    this.openAddNewAppPanel = false;
    this.ngOnInit();
    this.downloadUrl && this.storageService.deleteFileFromStorageByUrl(this.downloadUrl);
    this.percentage = 0;
    this.downloadUrl = undefined;

    this.downloadUrlIpa && this.storageService.deleteFileFromStorageByUrl(this.downloadUrlIpa);
    this.percentageIpa = 0;
    this.downloadUrlIpa = undefined;

    this.downloadUrlVideo && this.storageService.deleteFileFromStorageByUrl(this.downloadUrlVideo);
    this.percentageVideo = 0;
    this.downloadUrlVideo = undefined;

    this.downloadUrlBnnr && this.storageService.deleteFileFromStorageByUrl(this.downloadUrlBnnr);
    this.percentageBnnr = 0;
    this.downloadUrlBnnr = undefined;

    this.storageIdentifier = undefined;
    this.currentSelectedApp = undefined;
  }

  ngAfterViewInit() {
    var elementArry = document.querySelectorAll(".browse .mat-form-field-suffix");
    elementArry.forEach((element) => {
      element.innerHTML = "BROWSE";
      (<HTMLElement>element).style.setProperty("color", "#007CAD");
      (<HTMLElement>element).style.setProperty("top", "-3px");
    });
  }

  closePanel() {
    this.resetAddAppForm();
    this.close.emit();
  }

  imagePanelController(evt) {
    this.imageUrlAd.emit(evt);
  }
}
