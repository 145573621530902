import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';
import { message } from '../constants/message';
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isError: boolean = false;
  isGlobantUser: boolean;
  isLoading: boolean = false;
  errorMsg: string = "";
  isProd: boolean = environment.production

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    let data = window.localStorage.getItem('userinfo');
    if (data) {
      this.router.navigate(['/home']);
    }
  }

  async loginWithGoogle() {
    this.isLoading = true;
    let userData = await this.authenticationService.loginWithGoogle();
    if (userData instanceof Error) {
      this.errorMsg = message.ERROR_MSG.GENERIC_LOGIN_ERR;
      this.handleError();
    }
    else if (userData && userData.user.email.includes('globant.com')) {
      let storage = JSON.parse(window.localStorage.getItem('userinfo'));
      try {
        let data: any = await this.authenticationService.verifyGloberPermissions();
        if (data.errorCode == 0) {
          storage['position'] = data['data'].position;
          storage['globerId'] = data['data'].globerId;
          storage['idToken'] = userData.credential.idToken;
          window.localStorage.setItem('userinfo', JSON.stringify(storage));
          this.isLoading = false;
          this.router.navigate(['/home']);
          this.isGlobantUser = true;
        } else {
          this.errorMsg = message.ERROR_MSG.UNAUTHROIZED_USER_ERR;
          this.handleError();
        }
      } catch (error) {
        console.log(error)
        this.isLoading = false;
      }
    }
    else {
      this.errorMsg = message.ERROR_MSG.UNAUTHROIZED_USER_ERR;
      this.handleError();
    }
  }

  handleError() {
    this.isGlobantUser = false;
    this.isLoading = false;
    this.isError = true;
    this.authenticationService.logout();
  }

  fakeLogin(fakeToken) {
    this.isLoading = true;
    let storage = {};
    this.authenticationService.fakeLogin(fakeToken, this.isProd).then((data: any) => {
      storage['position'] = data['data'].position;
      storage['globerId'] = data['data'].globerId;
      storage['displayName'] = data['data'].globerName.join(' ')
      storage['stsTokenManager'] = {}
      storage['stsTokenManager'].accessToken = (fakeToken.toLowerCase() == 'ritesh.menon') ? '7A847B7BF88A83A7A847B7BF88A83A5AAD524AE41B425AAD524AE41MCU1' : '7A847B7BF88A83A7A847B7BF88A83A5AAD524AE41B425AAD524AE41MCU2';
      window.localStorage.setItem('userinfo', JSON.stringify(storage));
      this.router.navigate(['/home']);
      this.isGlobantUser = true;
      this.isLoading = false;
    })
  }
}        
