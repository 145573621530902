export const message = {
    DEFAULT_APP_LOGO: "../../assets/images/blank app.png",
    ADD_NEW_LINK_TITLE: "New App Store link",
    UPDATE_LINK_TITLE: "Update App Store link",
    OPERTAION_TYPE: {
        1: "Update Link",
        2: "Add New Link",
        3: "Update App",
        4: "Add New App",
    },

    LOG_BOOK_ACTION: {
        1: 'Created',
        2: 'Updated',
        3: 'Enabled',
        4: 'Disabled'
    },

    TYPE_OF_UPDATE: {
        1: "Mandatory",
        2: "Optional",
        "": "-",
        null: "-"
    },

    ERROR_MSG: {
        GENERIC_LOGIN_ERR: "Something went wrong please refresh and try again",
        NOT_GLOBANT_USRR_ERR: "Please login with globant account",
        UNAUTHROIZED_USER_ERR: "You are not authorized to access this application"
    },
    APP_LIST: [{
        'featuredApp': false,
        'appLogo': "../../assets/images/Mask Group 8234@2x.png",
        'appName': "BetterMe",
        'version': "1.1",
        'relDate': "Aug 28, 2020",
        'lastUpdateDate': "Nov 9, 2020",
        'typeOfUpdate': "Mandatory",
        'size': "26MB",
        'cta': "Update",
        'disabled': false
    }]
};