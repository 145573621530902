import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { StorageService } from '../service/storage.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { FileValidator } from 'ngx-material-file-input';
import { message } from '../constants/message';
// import { emailDomainValidator } from '../customError';
import { UserValidators, FileTypeValidatior } from '../customError';
import { DrapAndDropComponent } from '../drap-and-drop/drap-and-drop.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild(DrapAndDropComponent) dragComponent: DrapAndDropComponent;
  userData: any;
  showLogoutCard: boolean = false;
  openAddNewLinkPanel: boolean = false;
  openAddNewAppPanel: boolean = false;
  openLogBook: boolean = false;
  public files1;
  newAppLinkPreForm: FormGroup
  newAppLinkForm: FormGroup;
  showNewAppForm: boolean = true;
  isChecked: boolean = true;
  percentage: any = 0;
  downloadUrl: any;
  currentAppLogo: string = message.DEFAULT_APP_LOGO;
  currentOpenedPanelTitle: string;
  currentSelectedApp: any;
  currrentAppIndex: any;
  currentOperation: string;
  appList: any;
  typeOfUpdate = message.TYPE_OF_UPDATE;
  storageIdentifier: string;
  isLoading: boolean = false;
  isSubmitting: boolean = false;
  openImagePanel: boolean = false;
  imagePanelData: string;
  openUpdateAppPanel: boolean = false;
  hideAppLogoField: boolean = false;

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private renderer2: Renderer2, private storageService: StorageService, private service: UserValidators) {

    this.initializeNewAppLinkForm();
  }

  // app new app link to store
  initializeNewAppLinkForm() {

    this.newAppLinkPreForm = new FormGroup({
      newAppLinkName: new FormControl('', Validators.compose(
        [Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)],
      ),
        // custom async validator
        this.service.userValidator())
    });

    this.newAppLinkForm = new FormGroup({
      newAppLinkUrl: new FormControl('', [
        Validators.required
      ]),
      newAppLinkLogo: new FormControl(this.files1, [
        Validators.required, FileValidator.maxContentSize(5242880), FileTypeValidatior('.png')
      ])
    });
  }


  ngOnInit(): void {
    this.userData = JSON.parse(window.localStorage.getItem('userinfo'));
    this.getAppList();
    this.newAppLinkForm.controls['newAppLinkLogo'].valueChanges.subscribe((files: any) => {
      if (this.newAppLinkForm.controls['newAppLinkLogo'].valid && files) {
        if (!Array.isArray(files)) {
          this.files1 = [files];
        } else {
          this.files1 = files;
        }
        let observable = this.storageService.pushFileToStorage(this.storageIdentifier, 'logo', this.files1);
        observable.uploadProgress$.subscribe(
          (percentage) => { this.percentage = Math.round(percentage) },
          (error) => { console.log(error) }
        );
        observable.downloadUrl$.subscribe(
          (downloadUrl) => { this.downloadUrl = downloadUrl; },
          (error) => { console.log(error); }
        );
      }
    });
  }

  getAppList() {
    this.isLoading = true;
    this.authenticationService.getAppList().then((resp: any) => {
      if (resp.errorCode == 0) {
        this.appList = resp.data.apps;
        this.isLoading = false;
      } else {
        this.errorHandling(resp.errorCode, this.getAppList);
      }
    }).catch((error) => {
      console.log(error)
      this.isLoading = false;
      this.logout();
    });
  }

  logout() {
    this.authenticationService.logout();
    this.isLoading = false;
    window.localStorage.removeItem('userinfo');
    this.router.navigate(['/login'])
  }

  ngAfterViewInit() {
    var elementArry = document.querySelectorAll(".browse .mat-form-field-suffix");
    elementArry.forEach((element) => {
      element.innerHTML = "BROWSE";
      (<HTMLElement>element).style.setProperty("color", "#007CAD");
      (<HTMLElement>element).style.setProperty("top", "-3px");
    });
  }

  verifyNewAppCred(form: FormGroup) {
    this.isSubmitting = true;
    if (this.currentOperation == message.OPERTAION_TYPE['2']) {
      this.authenticationService.getUniqueStorageIdentifier(form.value.newAppLinkName).then(async (data: any) => {
        if (data.errorCode == 0) {
          form.disable();
          this.showNewAppForm = false;
          this.storageIdentifier = data.data.id;
          this.isSubmitting = false;
        } else if (data.errorCode == 4013) {
          await this.authenticationService.refreshTokenGenerater();
          this.verifyNewAppCred(form);
        }
      }).catch((err) => {
        this.isSubmitting = false;
      })
    }
  }

  openAddNewAppLinkToStorePanel(opertation) {
    if (opertation == message.OPERTAION_TYPE['2']) {
      this.openAddNewLinkPanel = true;
      this.currentAppLogo = message.DEFAULT_APP_LOGO;
      this.currentOpenedPanelTitle = message.ADD_NEW_LINK_TITLE;
      this.currentOperation = message.OPERTAION_TYPE['2'];
    } else {
      this.currentOperation = message.OPERTAION_TYPE['4'];
      this.openAddNewAppPanel = true;
      this.currentAppLogo = message.DEFAULT_APP_LOGO;
    }
  }

  // used for update purpose only
  openPanel(app, index) {
    this.currrentAppIndex = index;
    this.storageIdentifier = app.id;
    this.currentOperation = app.appStoreLink ? "Update Link" : "Update";
    this.currentSelectedApp = app;
    if (this.currentOperation == message.OPERTAION_TYPE['1']) {
      this.openAddNewLinkPanel = true;
      this.showNewAppForm = false;
      this.hideAppLogoField = true;
      this.isChecked = !app.isDisabled;
      this.newAppLinkPreForm.controls['newAppLinkName'].setValue(app.name);
      this.newAppLinkForm.controls['newAppLinkUrl'].setValue(app.appStoreLink);
      this.newAppLinkForm.controls['newAppLinkLogo'].setValidators([FileValidator.maxContentSize(5242880), FileTypeValidatior('.png')]);
      this.newAppLinkForm.get('newAppLinkLogo').updateValueAndValidity();
      this.currentAppLogo = app.logo;
      this.currentOpenedPanelTitle = message.UPDATE_LINK_TITLE;
    } else {
      var temp = Object.assign({}, app);
      this.currentSelectedApp = temp;
      this.openUpdateAppPanel = true;
    }
  }

  //submitting new app link or updating link
  submitLink() {
    this.isSubmitting = true;
    let obj = {
      'name': this.newAppLinkPreForm.controls['newAppLinkName'].value,
      'appStoreLink': this.newAppLinkForm.controls['newAppLinkUrl'].value,
      'logo': (this.downloadUrl) ? this.downloadUrl : this.appList[this.currrentAppIndex].logo,
      'logoName': (this.downloadUrl) ? this.files1[0]._files[0].name : this.appList[this.currrentAppIndex].logoName,
      "isDisabled": !this.isChecked
    }
    if (this.currentOperation == message.OPERTAION_TYPE['2']) {    //********* Adding the link ***********
      obj['id'] = this.storageIdentifier;
      this.authenticationService.submitAppData(obj).then((data: any) => {
        if (data.errorCode == 0) {
          this.downloadUrl = undefined;
          this.resetAddAppLinkForm();
          this.isSubmitting = false;
        } else {
          this.errorHandling(data.errorCode, this.submitLink)
        }
      }).catch((error) => {
        console.error(error);
        this.isSubmitting = false;
      })
    } else {  //******** Updating the link ***************
      obj['comment'] = "";
      this.authenticationService.updateAppLinkData(obj, this.appList[this.currrentAppIndex].id).then((data: any) => {
        if (data.errorCode == 0) {
          this.downloadUrl = undefined;
          this.resetAddAppLinkForm();
          this.isSubmitting = false;
        } else {
          this.errorHandling(data.errorCode, this.submitLink)
        }
      }).catch((err) => {
        console.error(err);
        this.isSubmitting = false;
      })
    }
  }

  resetAddAppLinkForm() {
    this.showNewAppForm = true;
    this.files1 = undefined;
    this.initializeNewAppLinkForm();
    this.openAddNewLinkPanel = false;
    this.ngOnInit();
    this.percentage = 0;
    this.downloadUrl && this.storageService.deleteFileFromStorageByUrl(this.downloadUrl);
    this.downloadUrl = undefined;
    this.storageIdentifier = undefined;
    this.hideAppLogoField = false;
    this.currentSelectedApp = undefined;
    this.isChecked = true;
  }

  updateFeaturedApp(idx) {
    let oldApp;
    oldApp = this.appList.find((app) => app.isFeatured);
    if (oldApp)
      oldApp.isFeatured = false;
    this.appList[idx].isFeatured = true;
    this.authenticationService.changeFeaturedApp(this.appList[idx].id, (oldApp) ? oldApp.id : '');
  }

  formatDate(date) {
    let tempDate = new Date(date * 1000)
    let month = tempDate.toLocaleString('default', { month: 'short' });
    let day = tempDate.getDate();
    let year = tempDate.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  imagePanelController(evt) {
    this.openImagePanel = true;
    this.imagePanelData = evt;
  }

  closeAddAppPanel() {
    this.openAddNewAppPanel = false;
    this.ngOnInit();
  }

  closeUpdateAppPanel() {
    this.openUpdateAppPanel = false
    this.ngOnInit();
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  toggleAppLogoField() {
    this.newAppLinkForm.controls['newAppLinkLogo'].setValidators([Validators.required, FileValidator.maxContentSize(5242880), FileTypeValidatior('.png')]);
    this.newAppLinkForm.get('newAppLinkLogo').updateValueAndValidity();
    this.hideAppLogoField = false;
  }

  async errorHandling(errorCode, cb) {
    if (errorCode == 4013) {
      await this.authenticationService.refreshTokenGenerater();
      cb.bind(this)();
    } else {
      this.logout();
    }
  }

  async toggleStoreLinkStatus() {
    this.isSubmitting = true;
    if (this.currentOperation == message.OPERTAION_TYPE['1'])
      await this.authenticationService.toggleAppStatus(this.currentSelectedApp.id, (this.isChecked) ? 'ENABLE' : 'DISABLE');
    this.isSubmitting = false;
  }

}