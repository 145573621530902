// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDEMbZERw-OgYNVuQ5UQAuq-LYHJcL0m_c",
    authDomain: "globant-app-store-dev.firebaseapp.com",
    databaseURL: "https://globant-app-store-dev.firebaseio.com",
    projectId: "globant-app-store-dev",
    storageBucket: "globant-app-store-dev.appspot.com",
    messagingSenderId: "1028233826006",
    appId: "1:1028233826006:web:8bcc7c21a8c7e32d8b8377"
  },
  baseUrl: "https://us-central1-globant-app-store-dev.cloudfunctions.net/admin_dev_v1_0_0",
  fixedToken: "Bearer 7A847B7BF88A83A7A847B7BF88A83A5AAD524AE41B425AAD524AE41B42"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
