import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment'

const URL = environment.baseUrl;
const veryAppName: string = 'dashboard/apps/store-app/create?action=verify';
const vrifyBundleIdf: string = 'dashboard/apps/publish-app/bundleId/verify';

@Injectable({
    providedIn: 'root'
})
export class UserValidators {
    currentApp: any;
    constructor(private http: HttpClient) { }

    search(text, path) {
        // debounce
        let accessToken = JSON.parse(window.localStorage.getItem('userinfo')).stsTokenManager.accessToken;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': `${environment.fixedToken}` });
        return timer(1000).pipe(
            switchMap(() => {
                // Check if username is available
                return this.http.post(`${URL}/${path}`, text, { headers: headers });
            })
        );
    }

    userValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            return this.search({ 'name': control.value }, veryAppName).pipe(
                map((res: any) => {
                    // if username is already taken
                    if (!res.success && control.dirty) {
                        // return error
                        return { 'userNameExists': true };
                    }
                })
            );
        };
    }

    bundleIdfValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            return this.search({ 'BIdentifier': control.value }, vrifyBundleIdf).pipe(
                map((res: any) => {
                    if (!res.success && control.dirty) {
                        return { 'bundleIdfExists': true };
                    }
                })
            );
        };
    }

    DuplicacyValidatior(fileType: any): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value && control.dirty) {
                if (fileType == 'version') {
                    return (this.currentApp.version >= control.value) ? { 'duplicacyError': true } : null;
                } else if (fileType == 'exe') {
                    return (this.currentApp.ipa.name == control.value._fileNames) ? { 'duplicacyError': true } : null;
                } else if (fileType == 'logo') {
                    return (this.currentApp.logoName == control.value._fileNames) ? { 'duplicacyError': true } : null;
                } else if (fileType == 'video' && this.currentApp.video) {
                    return (this.currentApp.video.name == control.value._fileNames) ? { 'duplicacyError': true } : null;
                } else if (fileType == 'banner') {
                    return (this.currentApp.banner.name == control.value._fileNames) ? { 'duplicacyError': true } : null;
                }
            } else {
                return null;
            }
        }
    }

}

export function FileTypeValidatior(fileType: any): ValidatorFn {
    return (control: AbstractControl) => {
        if (control.value) {
            if (Array.isArray(fileType)) {
                let fileName = control.value._fileNames.toLowerCase();
                if (fileName.endsWith(fileType[0]) || fileName.endsWith(fileType[1]) || (fileType[2] && fileName.endsWith(fileType[2]))) {
                    return null
                } else {
                    return { 'typeError': true }
                }
            }
            else if (control.value._fileNames.toLowerCase().endsWith(fileType)) {
                return null
            } else {
                return { 'typeError': true }
            }
        } else {
            return null;
        }
    }
}