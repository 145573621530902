<div>
    <div class="home-container">
        <div class="left-background">
            <img height="100%" width="80%" src="../../assets/images/partial-desktop.png" alt="background_img" />
        </div>
        <div class="progress-bar" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
        <!-- Add new App Link Container -->
        <div class="add-link-container" [ngClass]="{'add_blur':openAddNewLinkPanel}">
            <div class="add-link-wrapper">
                <div class="progress-bar" *ngIf="isSubmitting">
                    <mat-spinner [diameter]="80"></mat-spinner>
                </div>
                <div class="add-link-form-title">
                    <div class="panel-app-logo">
                        <img height="35px" width="35px" [src]="currentAppLogo" alt="background_img" />
                    </div>
                    <div class="app-slider-title"> {{currentOpenedPanelTitle}} </div>
                    <div class="cancel-btn" (click)="resetAddAppLinkForm()"> ✕ </div>
                </div>
                <hr>
                <div class="add-link-form">
                    <div class="app-enable-slider">
                        <mat-slide-toggle [(ngModel)]="isChecked" [color]="'primary'" (change)="toggleStoreLinkStatus()"
                            [disabled]="currentOperation == 'Add New Link'">
                        </mat-slide-toggle>
                        <span>{{isChecked ? 'Enabled' : 'Disabled'}}</span>
                    </div>
                    <form [formGroup]="newAppLinkPreForm">
                        <mat-form-field appearance="outline">
                            <mat-label>App name *</mat-label>
                            <input matInput formControlName="newAppLinkName" maxlength="30" />
                            <mat-error *ngIf="newAppLinkPreForm.get('newAppLinkName').hasError('required')">
                                App name is required
                            </mat-error>
                            <mat-error *ngIf="newAppLinkPreForm.get('newAppLinkName').hasError('pattern')">
                                Only Alphanumeric character is allowed
                            </mat-error>
                            <mat-error *ngIf="newAppLinkPreForm.get('newAppLinkName').hasError('userNameExists')">
                                App name already taken
                            </mat-error>
                            <!-- <mat-icon matSuffix class="error-icon"
                                *ngIf="newAppLinkPreForm.controls.newAppLinkName.touched && newAppLinkPreForm.controls.newAppLinkName.errors">
                                error</mat-icon> -->
                            <mat-hint align="end">{{newAppLinkPreForm.get('newAppLinkName').value.length}} / 30
                            </mat-hint>
                            <mat-hint align="start" *ngIf="newAppLinkPreForm.pending">Loading...</mat-hint>
                        </mat-form-field>
                    </form>

                    <div class="pre-submit-new-app"
                        (click)="newAppLinkPreForm.valid && !newAppLinkPreForm.pending && verifyNewAppCred(newAppLinkPreForm)"
                        [hidden]="!showNewAppForm">
                        Next
                    </div>
                    <div [hidden]="showNewAppForm">
                        <form [formGroup]="newAppLinkForm">
                            <mat-form-field appearance="outline">
                                <mat-label>App Store URL *</mat-label>
                                <input matInput formControlName="newAppLinkUrl" maxlength="2000" />
                                <mat-error *ngIf="newAppLinkForm.get('newAppLinkUrl').hasError('required')">
                                    App store url is required
                                </mat-error>
                                <mat-hint align="end">{{newAppLinkForm.get('newAppLinkUrl').value.length}} / 2000
                                </mat-hint>
                            </mat-form-field>

                            <div [hidden]="hideAppLogoField">
                                <mat-form-field appearance="outline" class="browse" [hidden]="true">
                                    <mat-label>App Icon *</mat-label>
                                    <ngx-mat-file-input formControlName="newAppLinkLogo"
                                        placeholder="Basic outline placeholder" [accept]="'image/png'">
                                    </ngx-mat-file-input>
                                    <mat-icon matSuffix>folder</mat-icon>
                                    <mat-error *ngIf="newAppLinkForm.get('newAppLinkLogo').hasError('required')">
                                        file is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="newAppLinkForm.get('newAppLinkLogo').hasError('maxContentSize')">
                                        The total size must not exceed 50kb
                                    </mat-error>
                                    <mat-error *ngIf="newAppLinkForm.get('newAppLinkLogo').hasError('typeError')">
                                        Only png files allowed
                                    </mat-error>
                                    <mat-hint align="start">Only png files allowed (preferred resolution 1024 * 1024)
                                    </mat-hint>
                                    <mat-hint align="end">{{percentage}} %</mat-hint>
                                </mat-form-field>
                            </div>
                        </form>
                        <div *ngIf="hideAppLogoField" style="width: 395px;">
                            <update-field [label]="'App Icon *'" [fileName]="currentSelectedApp.logoName"
                                (remove)="toggleAppLogoField()"></update-field>
                        </div>
                        <div class="submit-new-app-link">
                            <button *ngIf="currentOperation == 'Add New Link'"
                                [disabled]="newAppLinkPreForm.pending || !newAppLinkForm.valid || !downloadUrl"
                                (click)="submitLink()">Submit
                                Link</button>
                            <button *ngIf="currentOperation == 'Update Link'" [disabled]="newAppLinkPreForm.pending || (newAppLinkPreForm.touched && !newAppLinkPreForm.valid) || 
                                (!newAppLinkForm.valid) || files1 && files1.length > 0 &&  !downloadUrl"
                                (click)="submitLink()">Update
                                Link</button>
                        </div>
                    </div>
                    <div class="dummy-fields" [hidden]="!showNewAppForm">
                        <div style="width: 395px;"></div>
                        <div style="width: 395px;"></div>
                        <div class="dummy-button"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Upadte App -->
        <div class="add-link-container" [ngClass]="{'add_blur':openUpdateAppPanel}">
            <update-app *ngIf="openUpdateAppPanel" [updateAppData]="currentSelectedApp" (close)="closeUpdateAppPanel()"
                (imageUrlUp)="imagePanelController($event)"></update-app>
        </div>

        <!-- Add new App Container -->
        <div class="add-link-container" [ngClass]="{'add_blur':openAddNewAppPanel}">
            <add-app *ngIf="openAddNewAppPanel" (close)="closeAddAppPanel()"
                (imageUrlAd)="imagePanelController($event)"></add-app>
        </div>
        <div class="home-body">
            <div class="wrapper">
                <div class="basic-details">
                    <div class="app-title">
                        <div class="app-logo">
                            <img height="173px" width="173px" src="../../assets/images/App logo@2x 2.png"
                                alt="background_img" />
                        </div>
                        <div class="app-slider-title app-name">
                            MISSION CONTROL<span class="green-circle"></span>
                        </div>
                    </div>
                    <div class="user-details">
                        <div class="user-info">
                            <div class="user-info-dp">
                                <img height="42px" width="42px" [src]='userData?.photoURL'>
                            </div>
                            <div class="user-info-email">
                                <span class="user-name">{{userData?.displayName}}</span>
                                <span class="user-email-id">{{userData?.position}}</span>
                            </div>
                            <div class="logout-arrow" (click)="showLogoutCard = !showLogoutCard"
                                [ngStyle]="{'transform':showLogoutCard == true ? 'rotate(-180deg)' : '' }">
                                <span>▾</span>
                            </div>
                        </div>
                        <div class="logout-card" (click)="logout()" *ngIf="showLogoutCard">
                            <span>LOG OUT</span>
                        </div>
                    </div>
                </div>
                <div class="action-items">
                    <div class="app-action-btn add-app-btn" (click)="openAddNewAppLinkToStorePanel('Add New App')">
                        <img src="../../assets/images/Group 8993@2x.png" width="17px" height="17px"><span>Add App</span>
                    </div>
                    <div class="app-action-btn add-link-btn" (click)="openAddNewAppLinkToStorePanel('Add New Link')">
                        <img src="../../assets/images/Group 8993@2x.png" width="17px" height="17px"><span>Add Link to
                            store</span>
                    </div>
                </div>
                <div class="app-list-container">
                    <table>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th><span class="app-name-header">App name</span></th>
                            <th>Version</th>
                            <th>Released on</th>
                            <th>Last updated</th>
                            <th>Type of update</th>
                            <th>Size</th>
                            <th></th>
                        </tr>
                        <tr *ngFor="let app of appList;let i = index;" [ngClass]="{'disabled-app' : app.isDisabled}">
                            <td (click)="!app.isDisabled && app.isFeatured!=='' && updateFeaturedApp(i)">
                                <div class="app-star-radio-btn" *ngIf="(app.releaseMode == 'INTERNAL')">
                                    <img src="../../assets/images/Component 204 – 1@2x.png" width="20px" height="20px"
                                        matTooltip="Feature this on the App Market" [matTooltipPosition]="'above'"
                                        *ngIf="!app.isFeatured">
                                    <img src="../../assets/images/filled-star.png" width="18px" height="18px"
                                        *ngIf="app.isFeatured">
                                </div>
                            </td>
                            <td (click)="openLogBook=true; currentSelectedApp=app" class="always-enabled">
                                <img src="../../assets/images/Group 11034@2x.png" width="25px" height="25px">
                            </td>
                            <td><img [src]="app.logo" width="45px" height="45px"></td>
                            <td><span class="app-name-value">{{app.name}}</span></td>
                            <td>{{app.version ? app.version : '-'}} </td>
                            <td>{{formatDate(app.releasedOn._seconds)}}</td>
                            <td>{{app.lastUpdated ? formatDate(app.lastUpdated._seconds) : '-'}}</td>
                            <td>{{typeOfUpdate[app.typeOfUpdate]}}</td>
                            <td>{{app.size ? formatBytes(app.size,2) : "-"}}</td>
                            <td class="always-enabled" (click)="openPanel(app , i)">
                                {{app.appStoreLink ? "Update Link" : "Update"}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="logbook-container" *ngIf="openLogBook">
            <app-log-book [appData]="currentSelectedApp" (close)="openLogBook = false"></app-log-book>
        </div>
        <div class="logbook-container" *ngIf="openImagePanel" (click)="openImagePanel =false">
            <img [src]="imagePanelData" width="370px" height="630px">
        </div>
    </div>
</div>