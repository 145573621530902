import { Injectable } from '@angular/core';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { from, Observable, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: AngularFireStorage) { }


  pushFileToStorage(storageIdentifier, subFolder, fileUpload: any) {
    const basePath = "/app_market";
    const filePath = `${basePath}/${storageIdentifier}/${subFolder}/${new Date().getTime()}/${fileUpload[0]._files[0].name}`;
    const uploadTask = this.storage.upload(filePath, fileUpload[0]._files[0]);
    this.getDownloadUrl(uploadTask, filePath);
    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl(uploadTask, filePath),
      uploadTask: uploadTask
    };
  }

  pushDragFileToStorage(storageIdentifier, fileUpload: any) {
    const basePath = "/app_market";
    const filePath = `${basePath}/${storageIdentifier}/${fileUpload.name}`;
    const uploadTask = this.storage.upload(filePath, fileUpload);
    this.getDownloadUrl(uploadTask, filePath);
    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl(uploadTask, filePath),
    };
  }

  private getDownloadUrl(uploadTask: AngularFireUploadTask, path: string, ): Observable<string> {
    return from(uploadTask).pipe(
      switchMap((_) => this.storage.ref(path).getDownloadURL()),
    );
  }

  // deleteFileFromStorage(fileUpload) {
  //   const basePath = "/app_market";
  //   const filePath = `${basePath}/test/`;
  //   const storageRef = this.storage.ref(filePath);
  //   storageRef.child(fileUpload.name).delete();
  // }

  deleteFileFromStorageByUrl(downloadUrl) {
    console.log(downloadUrl);
    this.storage.storage.refFromURL(downloadUrl).delete();
  }
}
