<div>
	<p [ngClass]="{'container-on-add-app':operation == 'add'}"> App screenshots *</p>
	<div class="container" appDnd (fileDropped)="onFileDropped($event)"
		[ngClass]="{'container-on-add-app':operation == 'add'}">
		<input type="file" #fileDropRef id="fileDropRef" multiple accept="image/png , image/jpg , .jpg"
			(change)="fileBrowseHandler($event.target.files)" />
		<div class="upload-container">
			<div>
				<img src="../../assets/images/Component 197 – 2@2x.png" width="39px" height="39px">
			</div>
			<div>
				<h3>Drag your files here <span>or</span></h3>
				<label for="fileDropRef">SELECT A FILE</label>
				<p>Allowed file type: PNG, JPG. <br>
					preferred size (1125 × 2000)<br>
					Min: 2 files Max: 8 files</p>
			</div>
		</div>
	</div>
	<div class="files-list" cdkDropList (cdkDropListDropped)="drop($event)"
		[ngClass]="{'files-list-add-app':operation == 'add'}">
		<div *ngFor="let file of files; let i = index" cdkDrag>
			<div class="single-file">
				<div class="drag-icon">
					<img src="../../assets/images/Component 202 – 5@2x.png" width="11px" height="11px">
				</div>
				<div class="file-icon" style="width: 50px">
					<img [src]="file?.path" width="47px" height="55px" (click)="sendImageData(file?.path)">
				</div>
				<div class="info">
					<h4 class="name">
						{{ file?.name }}
					</h4>
					<div class="progress-cont" *ngIf="file?.progress < 100">
						<div class="progress" [style.width]="file.progress + '%'"></div>
					</div>
					<h4 class="size" *ngIf="file?.progress > 99">
						{{ formatBytes(file?.size , 2) }}
					</h4>
				</div>
				<div class="percent-status" *ngIf="file?.progress < 100">{{file.progress}}%</div>
				<div class="delete" (click)="deleteFile(i , file.path)">
					✕
				</div>
			</div>
			<hr>
		</div>
	</div>
</div>