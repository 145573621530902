<div class="add-app-wrapper">
    <div class="progress-bar" *ngIf="isSubmitting">
        <mat-spinner [diameter]="80"></mat-spinner>
    </div>
    <div class="add-link-form-title">
        <div class="panel-app-logo">
            <img height="35px" width="35px" [src]="currentAppLogo" alt="background_img" />
        </div>
        <div class="app-slider-title">
            New App
        </div>
        <div class="app-enable-slider">
            <mat-slide-toggle [(ngModel)]="isChecked" [color]="'primary'" [disabled]='true'></mat-slide-toggle>
            <span>{{isChecked ? 'Enabled' : 'Disabled'}}</span>
        </div>
        <div class="cancel-btn" (click)="closePanel()">
            ✕
        </div>
    </div>
    <hr>
    <div class="add-app-form">
        <form [formGroup]="newAppAddForm" class="new-app-section">
            <div class="new-app-section1">
                <form [formGroup]="newAppAddPreForm" class="">
                    <div class="new-app-section1-pre-form">
                        <mat-form-field appearance="outline">
                            <mat-label>App name *</mat-label>
                            <input matInput formControlName="newAppLinkName" maxlength="30" />
                            <mat-error *ngIf="newAppAddPreForm.get('newAppLinkName').hasError('required')">
                                App name is required
                            </mat-error>
                            <mat-error *ngIf="newAppAddPreForm.get('newAppLinkName').hasError('pattern')">
                                Only Alphanumeric character is allowed
                            </mat-error>
                            <mat-error *ngIf="newAppAddPreForm.get('newAppLinkName').hasError('userNameExists')">
                                App name already taken
                            </mat-error>
                            <mat-hint align="end">{{newAppAddPreForm.get('newAppLinkName').value.length}} /
                                30
                            </mat-hint>
                            <mat-hint align="start" *ngIf="newAppAddPreForm.get('newAppLinkName').pending">
                                Loading...</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Version Number*</mat-label>
                            <input matInput formControlName="newAppVersion" maxlength="64" />
                            <mat-error *ngIf="newAppAddPreForm.get('newAppVersion').hasError('required')">
                                Version number is required
                            </mat-error>
                            <mat-error *ngIf="newAppAddPreForm.get('newAppVersion').hasError('pattern')">
                                Only dot and numbers are allowed e.g. x, x.x, x.x.x
                            </mat-error>
                            <mat-hint align="end">{{newAppAddPreForm.get('newAppVersion').value.length}} /
                                64
                            </mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Bundle Identifier *</mat-label>
                            <input matInput formControlName="newAppBunbleIdf" maxlength="256" />
                            <mat-error *ngIf="newAppAddPreForm.get('newAppBunbleIdf').hasError('required')">
                                Bundle identifier is required
                            </mat-error>
                            <mat-error *ngIf="newAppAddPreForm.get('newAppBunbleIdf').hasError('pattern')">
                                Only characters and dot allowed
                            </mat-error>
                            <mat-error *ngIf="newAppAddPreForm.get('newAppBunbleIdf').hasError('bundleIdfExists')">
                                Bundle Identifier already taken
                            </mat-error>
                            <mat-hint align="end">{{newAppAddPreForm.get('newAppBunbleIdf').value.length}} /
                                256
                            </mat-hint>
                            <mat-hint align="start" *ngIf="!newAppAddPreForm.get('newAppBunbleIdf').pending">
                                e.g com.domainname.appname</mat-hint>
                            <mat-hint align="start" *ngIf="newAppAddPreForm.get('newAppBunbleIdf').pending">
                                Loading...</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="pre-submit-new-app"
                        (click)="newAppAddPreForm.valid && !newAppAddPreForm.pending&& verifyNewAppCred(newAppAddPreForm)"
                        [hidden]="!showNewAppForm">
                        Next
                    </div>
                </form>
                <div class="add-app-post-form-wrapper" [hidden]="showNewAppForm">
                    <mat-form-field appearance="outline" class="browse">
                        <mat-label>Executable file *</mat-label>
                        <ngx-mat-file-input formControlName="newAppExecutableFile"
                            placeholder="Basic outline placeholder" [accept]="'.ipa'"></ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                        <mat-error *ngIf="newAppAddForm.get('newAppExecutableFile').hasError('required')">
                            Executable file is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppExecutableFile').hasError('maxContentSize')">
                            The total size must not exceed 1GB
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppExecutableFile').hasError('typeError')">
                            Allowed file type: IPA
                        </mat-error>
                        <mat-hint align="start"> Allowed file type: IPA </mat-hint>
                        <mat-hint align="end">{{percentageIpa}} %</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="browse">
                        <mat-label>App Icon *</mat-label>
                        <ngx-mat-file-input formControlName="newAppLogo" placeholder="Basic outline placeholder"
                            [accept]="'image/png'">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                        <mat-error *ngIf="newAppAddForm.get('newAppLogo').hasError('required')">
                            Logo is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppLogo').hasError('maxContentSize')">
                            The total size must not exceed 50kb
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppLogo').hasError('typeError')">
                            Allowed file type: PNG(1024x1024)
                        </mat-error>
                        <mat-hint align="start">Allowed file type: PNG(1024x1024)
                        </mat-hint>
                        <mat-hint align="end">{{percentage}} %</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="browse">
                        <mat-label>Demo Video</mat-label>
                        <ngx-mat-file-input formControlName="newAppDemoVideo" placeholder="placeholder"
                            [accept]="'video/mp4 , .mov , video/m4v'">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                        <!-- <mat-error *ngIf="newAppAddForm.get('newAppDemoVideo').hasError('required')">
                            file is <strong>required</strong>
                        </mat-error> -->
                        <mat-error *ngIf="newAppAddForm.get('newAppDemoVideo').hasError('maxContentSize')">
                            The total size must not exceed 500 MB
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppDemoVideo').hasError('typeError')">
                            Allowed file type: M4V, MP4, and MOV format
                        </mat-error>
                        <mat-hint align="start">Allowed file type: M4V, MP4 and MOV format </mat-hint>
                        <mat-hint align="end">{{percentageVideo}} %</mat-hint>
                    </mat-form-field>
                </div>
                <div class="dummy-fields" [hidden]="!showNewAppForm">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="new-app-section2">
                <div [hidden]="showNewAppForm">
                    <mat-form-field appearance="outline">
                        <mat-label>Description *</mat-label>
                        <textarea matInput rows="14" style="resize: none;" formControlName="newAppDescription"
                            maxlength="4000"></textarea>
                        <mat-error *ngIf="newAppAddForm.get('newAppDescription').hasError('required')">
                            App description is required
                        </mat-error>
                        <mat-hint align="end">{{newAppAddForm.get('newAppDescription').value.length}} / 4000
                        </mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <textarea matInput rows="14" style="resize: none;" formControlName="newAppInstruction"
                            maxlength="500"></textarea>
                        <mat-error *ngIf="newAppAddForm.get('newAppInstruction').hasError('required')">
                            App Instructions is required
                        </mat-error>
                        <mat-hint align="end">{{newAppAddForm.get('newAppInstruction').value.length}} / 500
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div [hidden]="!showNewAppForm"></div>
            </div>
            <div class="new-app-section3">
                <div [hidden]="showNewAppForm">
                    <mat-form-field appearance="outline" class="browse">
                        <mat-label>App Banner *</mat-label>
                        <ngx-mat-file-input formControlName="newAppBanner" placeholder="Basic outline placeholder"
                            [accept]="'image/png , image/jpg , .jpg'">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                        <mat-error *ngIf="newAppAddForm.get('newAppBanner').hasError('required')">
                            Banner file is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppBanner').hasError('maxContentSize')">
                            The total size must not exceed 50kb
                        </mat-error>
                        <mat-error *ngIf="newAppAddForm.get('newAppBanner').hasError('typeError')">
                            Allowed file type: PNG, JPG(788 × 446)
                        </mat-error>
                        <mat-hint align="start">Allowed file type: PNG, JPG(788 × 446)
                        </mat-hint>
                        <mat-hint align="end">{{percentageBnnr}} %</mat-hint>
                    </mat-form-field>
                </div>
                <div class="dummy-fields" *ngIf="showNewAppForm">
                    <div></div>
                </div>
                <div class="upload-screen-shot">
                    <app-drap-and-drop #ref [storageIdentifier]="storageIdentifier" [operation]="'add'"
                        (imageUrl)="imagePanelController($event)" [hidden]="showNewAppForm">
                    </app-drap-and-drop>
                    <div class="dummy-fields" *ngIf="showNewAppForm">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </form>
        <hr>
        <div class="submit-new-app">
            <button
                [disabled]="newAppAddPreForm.pending || !newAppAddForm.valid || !downloadUrl 
                || !downloadUrlIpa || files3 && files3.length > 0 && !downloadUrlVideo || !downloadUrlBnnr || !ref.enableSubmit"
                (click)="submitApp(newAppAddForm.value)">Submit
                App</button>
        </div>
    </div>
</div>